.p-footer{
  text-align: left;
  &__head{
    padding: 4rem 0 2rem;
    background: #09242f url('/asset/img/footer_map_img@2x.jpg') center / auto 100% no-repeat;
    color: @white;
    min-height: 351px;
  }
  &__row{
    display: flex;
    align-items: flex-end;
    padding: 0;
    margin: auto;
    width: 100%;
    max-width: 990px;
    .column-1-2{
      padding: 0 15px;
    }
  }
  &__logo{
    >a{
      .hoverOpacity;
    }
  }
  &__contact{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 3rem;
    .button{
      padding-left: 3rem;
      padding-right: 3rem;
      font-weight: 600;
      font-size: 1.8rem;
      i.fa{ margin-right: 0.5em;}
    }
    .button-border-white{
      &,&:hover,&:focus{
        border-width: 2px;
      }
    }
    .tel{
      color: inherit;
      font-size: 2.6rem;
      font-family: 'Roboto', sans-serif;
      font-weight: 700;
      margin: 0 0 0 2rem;
      i.fa{ margin-right: 0.3em;}
    }
  }
  &__address{
    &__name,&__desc,p,address{
      display: inline;
      color: @white;
      font-style: normal;
      font-size: 1.4rem;
      line-height: 1.7;
    }
    &__name{
      font-size: 1.5rem;
      font-weight: 600;
      margin-right: 1em;
    }
    &__desc{
      p{
        display: block;
        margin: 0;
      }
    }
  }
  &__availablearea{
    dt,dd{
      color: @white;
    }
    dt{
      font-size: 1.5rem;
    }
    dd{
      font-size: 1.3rem;
    }
  }

  &__foot{
    text-align: center;
    background-color: @primary;
    padding: 10px 0 20px;
  }
  &__nav,
  &__subnav{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 900px;
    margin: auto;
    a{
      color: @white;
    }
  }
  &__nav{
    justify-content: space-around;
  }
  &__nav-item{
    &:extend(.button all);
    &:extend(.button-text all);
    line-height: 1.375;
    font-weight: 600;
    font-size: 1.5rem;
    &:not(:disabled){
      color: @white;
    }
  }
  &__subnav-item{
    &:extend(.button all);
    &:extend(.button-text all);
    &:extend(.button-xsmall all);
    font-size: 1.3rem;
    &:not(:disabled){
      color: @white;
    }
  }
  a&__nav-item,
  a&__subnav-item{
    &:hover,&:focus{
      &:not(:disabled){
        color: fade(@white,70%);
      }
    }
  }
}
footer{
  .copyright,
  .promotion{
    background-color: @primary;
    margin: 0; padding: 10px 0;
    a,a:visited{
      color: @white;
    }
  }
  .copyright{
    a{
      font-size: 1.4rem;
      font-weight: 600;
    }
  }
}

footer>a.pagetop{
  display: none;
}
.p-pagetop{
  position: absolute;
  display: block;
  right: 15px; bottom: 100%;
  @media @xsmall {
    display: none;
  }
  img{
    display: block;
    .transition(all @transition-fast ease-in-out);
  }
  &:hover{
    // img{ animation: swing-v .5s ease-in-out infinite alternate;}
    img{
      .translateY(-15px);
    }
  }
  &__wrap{
    position: fixed;
    position: sticky;
    bottom: 0;
    padding-bottom: 0rem;
    width: 100%;
    max-width: 1230px;
    padding-left: 15px;
    padding-right: 15px;
    margin: auto;
    .transition(all @transition-fast ease-in-out);
    &.is-hidden{
      .translateY(200px);
      opacity: 0;
      z-index: 0;
    }
  }
}