.breadcrumbs{
  margin-top: 0;
  margin-bottom: 5px;
  ol{
    background: none;
    .container;
    li{
      letter-spacing: 1px;
      text-shadow: 1px 1px 7px #ffffff, -1px -1px 7px #ffffff, 1px -1px 7px #ffffff, -1px 1px 7px #ffffff;
      &:first-child:before{
        font-family: 'fontAwesome';
        content: none;
        display: inline-block;
        color: #3C2E3D;
      }
      &:first-child a{
        padding-left: 0;
        display: inline-block;
      }
      >span{
        padding-left: 0.5em;
        padding-top: 5px\0;
      }
      a,
      a:visited{
        background: none;
        line-height: 1.8;
        padding-left:1em;
        padding-right: .5em;
        padding-top: 5px\0;
        color: #3C2E3D;
        font-size: 1.1rem;
      }
      a:hover,
      a:hover span{
        color: #3C2E3D;
      }
      a:before,
      a:after{
        border:none;
        display: none;
      }
      a:after{
        display: inline-block;
        font-family: 'fontAwesome';
        content:'\f105';
        margin-top: 0;
        line-height: 0;
        right: 0em;
        font-size: 18px;
        color: lighten(#3C2E3D,40%);
      }
      a:hover,
      a:focus{
        background: none;
        // color:inherit;
        text-decoration: underline;
      }
    }
  }
}