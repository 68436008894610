.toolbar{
  @media @small-up {
    position: absolute;
    z-index: 10;
  }
}

#gmenu{
  display: none;
}

.p-header{
  width: 100%;
  background-color: fade(@white,80%);
  padding-top: 20px;
  padding-bottom: 19px;
  @media @small-up {
    position: absolute;
    top: 40px; left: 0;
    z-index: 10;
  }
  .container{
    max-width: 1370px;
    padding: 0 25px;
  }
  &__navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__logo{
    margin: 0 auto 0 0;
    @media (max-width:1140px) {
      img{
        max-width: 200px;
      }
    }
    >a{
      .hoverOpacity;
    }
  }
  &__gnav{
    display: flex;
    &-item{
      &:extend(.button all);
      &:extend(.button-text all);
      position: relative;
      padding: 1.3rem 1.7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.375;
      font-weight: 600;
      @media (max-width: 1140px) {
        font-size: 1.4rem;
        padding: 1.2rem;
      }
      &:after{
        position: absolute;
        bottom: 5px; left: 50%;
        content: '';
        .size(0,2px);
        background-color: @primary;
        opacity: 0;
        .translateX(-50%);
        .transition(all @transition-fast ease-in-out);
      }
      &:hover,&:focus,&.current{
        &:after{
          width: 80%;
          opacity: 1;
        }
      }
    }
  }
  &__contact{
    margin-left: 3rem;
    margin-top: -2rem;
    &__tel{
      font-family: 'Roboto', sans-serif;
      font-size: 2.6rem;
      font-weight: 500;
      margin-bottom: 0.2em;
      color: @quaternary;
      .fa{ margin-right: 0.3em;}
    }
    .button{
      font-weight: 600;
      .fa{ margin-right: 0.3em;}
      display: block;
    }
  }
}

.mainImg{
  margin-bottom: 0;
  & &Inner{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 370px;
    padding-top: 150px;
    background: url('/asset/img/cont-title_bg@2x.jpg') center / cover no-repeat;
    >h2{
      margin: 0;
      font-size: 3.2rem;
      font-weight: 600;
      letter-spacing: 0.2em;
      text-indent: 0.2em;
      color: @white;
    }
  }
}