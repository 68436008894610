/*
 * Button
 */


// Button Grey
@button-grey-color: @white;
@button-grey-background: @grey;
@button-grey-border: none;
@button-grey-hover-color: @button-grey-color;
@button-grey-hover-background: darken(@button-grey-background, 10%);
@button-grey-hover-border: none;

// Button white
@button-white-color: @secondary;
@button-white-background: @white;
@button-white-border: none;
@button-white-hover-color: @button-white-color;
@button-white-hover-background: tint(@secondary, 80%);
@button-white-hover-border: none;

// Button Border white
@button-border-white-color: @white;
@button-border-white-background: transparent;
@button-border-white-border: 1px solid @button-border-white-color;
@button-border-white-hover-color: @body-color;
@button-border-white-hover-background: @white;
@button-border-white-hover-border: @button-border-white-border;



// Button Border white
.button-border-white {
  .button-theme(
    @button-border-white-color,
    @button-border-white-background,
    @button-border-white-border,
    @button-border-white-hover-color,
    @button-border-white-hover-background,
    @button-border-white-hover-border
  );
}

// Button grey
.button-grey {
  .button-theme(
    @button-grey-color,
    @button-grey-background,
    @button-grey-border,
    @button-grey-hover-color,
    @button-grey-hover-background,
    @button-grey-hover-border
  );
}

// Button white
.button-white {
  .button-theme(
    @button-white-color,
    @button-white-background,
    @button-white-border,
    @button-white-hover-color,
    @button-white-hover-background,
    @button-white-hover-border
  );
}

.c-btn-right{
  @arrow: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{body-color}" stroke-miterlimit="10"/></svg>');
  @arrow-on: escape('<svg height="5.84" viewBox="0 0 18.9 5.84" width="18.9" xmlns="http://www.w3.org/2000/svg"><path d="m0 5.34h17.68l-5.09-4.98" fill="none" stroke="@{white}" stroke-miterlimit="10"/></svg>');
  &:extend(.button-border all);
  width: 100%;
  max-width: 180px;
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  &:after{
    display: inline-block;
    margin-left: .5em;
    content: '';
    .size(1.4em,0.5em);
    background: center / cover no-repeat;
    background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow}');
  }
  &:hover, &:focus{
    &:after{
      background-image: url('data:image/svg+xml;charset=UTF-8,@{arrow-on}');
    }
  }
}

.button-rounded{
  border-radius: 100px;
}