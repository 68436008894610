/*
 * Title
 */

.c-block-title{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 2.6rem;
  font-weight: 600;
  letter-spacing: 0.05em;
  line-height: 1.8;
  @media @xsmall {
    font-size: 2.2rem;
  }
  small{
    display: inline-block;
    padding: 0 2rem;
    margin-top: 0.5em;
    font-size: 1.5rem;
    font-family: 'Roboto', sans-serif;
    background-color: @yellow;
    color: @primary;
    font-weight: 500;
    line-height: 1.3;
    @media @xsmall {
      font-size: 1.4rem;
    }
  }
}