// テンプレートURL
@template-directory: '//secure.hybs.jp/_site_template/layout/110_v1';

//import turret override
@import 'turret/turret';

//import components
@import '_components';

//import extend mixins
@import (reference) '_mixins_extend';

//webfont読み込み
@fontfile_path: '../fonts/';
@import 'fonts/font-awesome';
@import 'fonts/Roboto';
// @import url('https://fonts.googleapis.com/css?family=Montserrat:400,700');

// デフォルトをカスタム用に上書きするスタイル(基本変更しない)
@import '_common';

// CMSベーシックパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/toolbar';
@import 'elements/breadcrumbs';
@import 'elements/paginations';
@import 'elements/block';

// 各セクションパーツインポート：サイト特有のカスタムも以下ファイル内に記載する
@import 'elements/header';
@import 'elements/side';
@import 'elements/footer';

// サイト特有の全体用カスタマイズを以下に記載

.is--company{

  .hybs-one-column(960px);
  @media @xsmall{
    .ui-content{
      padding-left: 15px;
      padding-right: 15px;
    }
  }
  #main > .element_detail{
    margin-bottom: 5rem;
  }
}